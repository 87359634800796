import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
// import { MenuComponent } from './menu/menu.component';
import { CountryModalComponent } from './country-modal/country-modal.component';
import { OtpModalComponent } from './otp-modal/otp-modal.component';
import { NgxOtpInputModule } from "ngx-otp-input";


@NgModule({
  declarations: [HeaderComponent,FooterComponent,CountryModalComponent,OtpModalComponent],
  imports: [
    CommonModule,NgxOtpInputModule
  ],
  exports:[HeaderComponent,FooterComponent,CountryModalComponent,OtpModalComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class CommonmodulesModule { }
